import { Component, Input, OnChanges, ChangeDetectionStrategy, Inject } from '@angular/core';
import SPORT_CONFIG, { SportConfigToken } from '@config/sport.config';
import { CodeState, MatchState, Team } from '@interfaces/match-data.interface';

import { Score } from '../../interfaces/score.interface';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-score',
    templateUrl: './score.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreComponent extends BaseComponent implements OnChanges {
    @Input('sport')
    public sportName: string;

    @Input()
    public head: boolean = false;

    @Input()
    public onlyPeriods: boolean = false;

    @Input()
    public onlyFinal: boolean = false;

    @Input()
    public oneline: boolean = true;

    @Input()
    public participant?: string;

    @Input()
    public participants?: { [prop: string]: Team };

    @Input('match_state')
    public matchState: MatchState | null = null;

    @Input()
    public codeState: CodeState | null = null;

    @Input()
    public data: Score;

    public dataNew: Score;

    @Input()
    public type: string = 'full';

    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }


    public ngOnChanges(): void {
        this.dataNew = { ...this.data };
        this.recalculate();
    }

    private recalculate(): void {
        const found: number = this.data.periods.findIndex((val): boolean => val.name === 'penalties');

        if (found !== -1 && this.matchState && this.isMatchAfter(this.matchState)) {
            const sumAll = SPORT_CONFIG.score.penalties.sumAll.includes(this.sportName);
            const normalTime = SPORT_CONFIG.score.penalties.normalTime.includes(this.sportName);
            const homeWin = this.data.periods[found]!.home_team > this.data.periods[found]!.away_team ? 1 : 0;
            const awayWin = this.data.periods[found]!.home_team < this.data.periods[found]!.away_team ? 1 : 0;

            this.dataNew.home_team = (this.data.home_team as number) +
                (sumAll ? this.data.periods[found]!.home_team : homeWin);
            this.dataNew.away_team = (this.data.away_team as number) +
                (sumAll ? this.data.periods[found]!.away_team : awayWin);

            if (normalTime) {
                this.dataNew.home_team = this.data.home_team;
                this.dataNew.away_team = this.data.away_team;
            }

            if (!this.head) {
                this.dataNew.periods = this.dataNew.periods.filter(
                    val => val.name !== 'penalties' && val.name !== 'extra-time',
                );
            }

            if (this.sportConfig.isFieldHockey(this.sportName) &&
                this.dataNew.away_team_normal_time && this.dataNew.home_team_normal_time) {
                const isWinnerHome = this.dataNew.home_team > this.dataNew.away_team;
                this.dataNew.home_team = isWinnerHome ? this.dataNew.home_team_normal_time + 1
                    : this.dataNew.home_team_normal_time;
                this.dataNew.away_team = !isWinnerHome ? this.dataNew.away_team_normal_time + 1
                    : this.dataNew.away_team_normal_time;
            }
        }

        if ((this.dataNew.home_text || this.dataNew.away_text) && this.sportConfig.isCricket(this.sportName)) {
            this.dataNew.home_team = this.dataNew.home_text ?? '';
            this.dataNew.away_team = this.dataNew.away_text ?? '';
        }

        this.dataNew.periods = this.dataNew.periods.filter(
            (val): boolean => val.name !== 'extra-time' || (val.name === 'extra-time' && val.home_team !== null),
        );
    }
}
