/**
 *  Service for soccer time
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class IceHockeyTime {
    public handle(
        minute: number,
        state: string,
    ): [number, number | null] {
        if (state === 'period_1') {
            if (minute >= 20) {
                minute = 19;
            }
        } else if (state === 'period_2') {
            if (minute >= 40) {
                minute = 39;
            }
        } else if (state === 'period_3') {
            if (minute >= 60) {
                minute = 59;
            }
        }

        if (minute >= 20) {
            minute = 19;
        }

        return [minute + 1, null];
    }
}
