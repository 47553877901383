/**
 *  Service for soccer time
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import SPORT_CONFIG from '@config/sport.config';
import { isArray } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class DefaultTime {
    private sportCodeName: string | null = null;

    public periodLength: number;

    public totalPeriods: number;

    public handle(minute: number): [number, number | null] {
        let time: number = minute + 1;

        if (this.periodLength) {
            time = Math.min(time, this.periodLength);
        } else if (this.sportCodeName! in SPORT_CONFIG.time.topLimits) {
            // @ts-ignore
            const limit = isArray(SPORT_CONFIG.time.topLimits[this.sportCodeName!])
            // @ts-ignore
                ? SPORT_CONFIG.time.topLimits[this.sportCodeName!][0]
            // @ts-ignore
                : SPORT_CONFIG.time.topLimits[this.sportCodeName!];
            time = Math.min(time, limit);
        }

        return [time, null];
    }

    public addSportCode(code: string): void {
        this.sportCodeName = code;
    }
}
