/**
 *  Sesponsive images
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';

import APP_CONFIG from 'src/app/config/app.config';


@Pipe({
    name: 'responsive',
})
export class ResponsivePipe implements PipeTransform {
    public transform(value: any, type?: string, size?: string): any {
        if (type === 'participant' && value) {
            if (
                !value.includes(APP_CONFIG.defaultAvatar) &&
                !value.includes(APP_CONFIG.defaultLogo) &&
                value.includes(APP_CONFIG.logoPath)
            ) {
                value = value.replace(
                    APP_CONFIG.logoPath,
                    APP_CONFIG.logoResponsivePath,
                );
                value = value.replace(/^(.+)\.(.+)$/, `$1-${size}.$2`);
            }
        }
        return `${value}`;
    }
}
