/* eslint-disable @angular-eslint/no-input-rename */
/**
 *  Component display match time
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    Component,
    OnInit,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import * as _ from 'underscore';

import { CodeState } from '@interfaces/match-data.interface';

import SPORT_CONFIG from '../../../config/sport.config';

import { SportTime } from './services/base-time.service';

declare const moment: any;

@Component({
    selector: 'app-match-time',
    templateUrl: './time.component.html',
    providers: [SportTime],
})
export class TimeComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public sportName: string;

    @Input()
    public matchId?: number;

    /**
     * Actual time
     */
    @Input()
    public time?: string | null;

    /**
     * period length
     */
    @Input()
    public periodLength: number | null = null;

    /**
     * Added time
     */
    @Input()
    public addTime: number;

    /**
     * Added time
     */
    @Input()
    set stoppageTimeAnnounced(val: string | null) {
        this.stoppageTimeAnnouncedIn = val;

        if (val !== null && typeof val !== 'undefined') {
            this.stoppageTimeAnnouncedIn = `${parseInt(val, 10)}`;
        }
    }

    public stoppageTimeAnnouncedIn: string | null = null;

    /**
     * Last update
     */
    @Input()
    public lastTimeUpdate?: number;

    /**
     * Actual state
     */
    @Input('code_state')
    public state: string;

    /**
     * Actual time
     */
    public currentTime: any;

    private interval: number | null;

    private lastDiff: number | null = null;

    private lastTime: any | null = null;

    public constructor(private service: SportTime) {}

    public ngOnInit(): void {
        this.service.set(this.sportName, this.periodLength);

        // if (SPORT_CONFIG.time.running.includes(this.sportName)) {
        //     this.interval = setInterval((): void => {
        //         if (!_.isUndefined(this.currentTime)) {
        //             this.currentTime.add(1, "seconds");
        //         }
        //     }, 1000);
        // }
    }

    public ngOnChanges(change: SimpleChanges): void {
        this.service.set(this.sportName, this.periodLength);
        if (
            this.time &&
            change.time &&
            change.time.previousValue !== change.time.currentValue &&
            change.time.currentValue !== null
        ) {
            const [minute, second] = this.time!.split(':', 2);
            if (minute && second) {
                this.currentTime = moment()
                    .hour(0)
                    .minute(+minute)
                    .second(+second);


                if (this.lastTimeUpdate && (
                    SPORT_CONFIG.isSoccer(this.sportName) ||
                    SPORT_CONFIG.isFutsal(this.sportName)
                )) {
                    this.lastTime = moment.unix(this.lastTimeUpdate).utc();
                }
            }
        }
    }

    public ngOnDestroy(): void {
        clearInterval(this.interval!);
    }

    public get timex(): any {
        let minuteSub = null;

        if (_.isUndefined(this.currentTime) || this.time === '00:00' || this.state === CodeState.PENALTIES) {
            return '';
        }
        const cloneTime = this.currentTime.clone();


        if (this.lastTimeUpdate && (
            SPORT_CONFIG.isSoccer(this.sportName) ||
                    SPORT_CONFIG.isFutsal(this.sportName)
        )) {
            const diff = moment().utc().diff(this.lastTime, 'seconds');
            this.lastDiff = diff;
            cloneTime.add(this.lastDiff, 'seconds');
        }

        const hour: any = +cloneTime.hour();
        let minute: any = +cloneTime.minute();
        let second: any = +cloneTime.second();
        // TODO: debug match
        // if (this.matchId && this.matchId === 3255113) {
        //     console.log(`Minute: ${this.time}`);
        //     console.log(`Diff seconds: ${this.lastDiff}`);
        //     console.log(`Counted: ${minute}`);
        //     console.log(cloneTime.format());
        //     console.log('----------------------------------');
        // }
        if (hour > 0) {
            minute += 60 * hour;
        }

        [minute, minuteSub] = this.service.handle(
            minute,
            this.state,
            this.addTime,
            this.time!,
        );

        minute += '';
        second += '';

        if (
            SPORT_CONFIG.time.running.includes(this.sportName as never) &&
            minuteSub === null
        ) {
            return `${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
        }

        if (minuteSub === null) {
            minuteSub = '';
        }

        return `${minute}<sup>${minuteSub}</sup><span class="blink">′</span>`;
    }
}
