import { Component, OnInit, Input, Inject } from '@angular/core';
import { trigger } from '@angular/animations';
import { SCORE_ANIMATION } from 'src/app/shared/animations/score.animation';
import { CodeState } from '@interfaces/match-data.interface';
import { Score } from '@interfaces/score.interface';
import { SportConfigToken } from '@config/sport.config';

@Component({
    selector: 'app-default-score-short',
    templateUrl: './default-score-short.component.html',
    animations: [trigger('score', SCORE_ANIMATION)],
})
export class DefaultScoreShortComponent implements OnInit {
    @Input()
    public data: Score;

    @Input('sport')
    public sportName: string | undefined;

    @Input()
    public onlyPeriods: boolean = false;

    @Input()
    public onlyFinal: boolean = false;

    @Input()
    public oneline: boolean = true;

    @Input('match_state')
    public matchState: string | null = null;

    @Input()
    public codeState: CodeState | null = null;

    public constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>) {}

    public ngOnInit(): void {}

    public trackByFn(index: number, item: Record<string, any>): any {
        return item.name;
    }

    get isWalkOverNullScore(): boolean {
        return this.codeState === CodeState.WALKOVER && +this.data.home_team === 0 && +this.data.away_team === 0;
    }

    get notFormatScore(): boolean {
        return this.sportConfig.isCricket(this.sportName);
    }

    get isCricket(): boolean {
        return this.sportConfig.isCricket(this.sportName);
    }

    /** u
     * Normalize score
     * @param  score
     * @return {string}u
     */
    public getScore(score: any): string {
        return score === null ? '-' : score;
    }
}
