<ng-container *ngIf="langLoaded && trans">
    <span
        data-cy="app-last-matches-item"
        class="badge app-last-matches-badge {{ classOb(match) }}"
        *ngFor="let match of matchesN.slice().reverse() | slice: 0:limit; trackBy: trackByIndex"
        data-toggle="tooltip"
        data-placement="top"
        data-html="true"
        title="<span class='latest-matches-tooltip'>{{ match.start_date | livDate: 'date' }} <br> {{
            getInfo(match)
        }}</span>"
    >
        {{ getStatus(match) }}
    </span>
    <ng-container *ngIf="spread">
        <div class="widget-team__form-text">
            <span
                *ngFor="let match of matchesN.slice().reverse() | slice: 0:limit; trackBy: trackByIndex"
                class="app-last-matches-badge app-badge-text {{ classTextOb(match) }} badge-text-success"
                [innerHTML]="getTextStatus(match)"
            ></span>
        </div>
    </ng-container>
</ng-container>
