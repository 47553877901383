/**
 *  Service for sports time
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { SportTimeInterface } from '@interfaces/sport-time.interface';
import CONFIG from '@config/sport.config';

import { SoccerTime } from './soccer-time.service';
import { IceHockeyTime } from './ice-hockey-time.service';
import { DefaultTime } from './default-time.service';
import { RunningTime } from './running-time.service';

@Injectable({
    providedIn: 'root',
})
export class SportTime {
    private obj: SportTimeInterface & { periodLength?: number };

    public set(sportName: string, periodLength: number | null = null): void {
        switch (sportName) {
            case 'soccer':
                this.obj = new SoccerTime();
                break;
            case 'ice-hockey':
                this.obj = new IceHockeyTime();
                break;
            default:
                this.obj = CONFIG.time.timeContinuesEachPeriod.includes(sportName)
                    ? new RunningTime() : new DefaultTime();
                this.obj.periodLength = periodLength as number;
                /*eslint-disable */
                (this.obj as any).addSportCode(sportName);
                /* eslint - enable */
                break;
        }
    }

    public handle(
        minute: number,
        state: string,
        addTime?: number,
        origTime?: string,
    ): [number, number] {
        return this.obj.handle(minute, state, addTime, origTime);
    }
}
