<ng-container *ngIf="type == 'short'">
    <app-default-score-short
        [data]="dataNew"
        [sport]="sportName"
        [onlyPeriods]="onlyPeriods"
        [match_state]="matchState"
        [codeState]="codeState"
        [oneline]="oneline"
        [onlyFinal]="onlyFinal"
    ></app-default-score-short>
</ng-container>
<ng-container *ngIf="type == 'full'">
    <app-default-score
        class="app-default-score"
        [sport]="sportName"
        [codeState]="codeState"
        [data]="dataNew"
        [match_state]="matchState"
        [onlyPeriods]="onlyPeriods"
        [oneline]="oneline"
        [head]="head"
    ></app-default-score>
</ng-container>
<ng-container *ngIf="type == 'match-detail'">
    <app-match-detail-score
        class="app-default-score"
        [data]="dataNew"
        [sport]="sportName"
        [participants]="participants"
        [match_state]="matchState"
        [onlyPeriods]="onlyPeriods"
        [oneline]="oneline"
        [head]="head"
    ></app-match-detail-score>
</ng-container>
