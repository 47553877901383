<ng-container *ngIf="!isCricket">
    <div class="text-center app-short-score" *ngIf="!onlyFinal">
        <span *ngFor="let score of data.periods; last as isLast">
            <span
                *ngIf="
                    score.home_team !== null &&
                        matchState != 'not-actual' &&
                        (matchState == null || matchState != 'before');
                    else homeNSScore
                "
            >
                {{ isWalkOverNullScore ? '-' : !notFormatScore ? +score.home_team : score.home_team }}</span
            ><ng-template #homeNSScore>-</ng-template>:<span
                *ngIf="
                    score.away_team !== null &&
                        matchState != 'not-actual' &&
                        (matchState == null || matchState != 'before');
                    else awayNSScore
                "
                >{{ isWalkOverNullScore ? '-' : !notFormatScore ? +score.away_team : score.away_team }}</span
            ><ng-template #awayNSScore>-</ng-template><span *ngIf="!isLast">, </span>
        </span>
    </div>
    <div class="text-center app-short-score-final" *ngIf="onlyFinal">
        <span
            *ngIf="
                matchState == null || (matchState != 'before' && data.home_team !== null && matchState != 'not-actual');
                else homeNSScore
            "
            [class.app-state-color-inplay]="matchState == 'inplay-live' || matchState == 'inplay-pause'"
        >
            <span [@score]="data.home_team">{{
                isWalkOverNullScore ? '-' : !notFormatScore ? +data.home_team : data.home_team
            }}</span>
        </span>
        <ng-template #homeNSScore><span>-</span></ng-template>
        <span [class.app-state-color-inplay]="matchState == 'inplay-live' || matchState == 'inplay-pause'">:</span>
        <span
            *ngIf="
                matchState == null || (matchState != 'before' && data.away_team !== null && matchState != 'not-actual');
                else awayNSScore
            "
            [class.app-state-color-inplay]="matchState == 'inplay-live' || matchState == 'inplay-pause'"
        >
            <span [@score]="data.away_team">{{
                isWalkOverNullScore ? '-' : !notFormatScore ? +data.away_team : data.away_team
            }}</span>
        </span>
        <ng-template #awayNSScore><span>-</span></ng-template>
    </div>
</ng-container>
