/**
 *  Animation score change
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { style, animate, transition, keyframes } from '@angular/animations';

const keyFrames = [];

const text = '#fff';
const text2 = text;

for (let x = 0; x < 10; x += 1) {
    if (x % 2 === 0) {
        keyFrames.push(
            style({
                color: '#f6b20c',
                padding: '2px',
                fontWeight: '800',
            }),
        );
    } else {
        keyFrames.push(style({ color: 'black', fontWeight: '800' }));
    }
}
export const SCORE_ANIMATION = [
    transition(':increment', [
        style({

            backgroundColor: '#df0303',
            color: text,
            fontWeight: '600',
            padding: '0px 2px',
        }),
        animate(
            '15s 4s',
            keyframes([
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
            ]),
        ),
        animate(
            '2s',
            style({
                transform: 'rotate3d(0,1, 0, 360deg)',
            }),
        ),
    ]),
    transition(':decrement', [
        style({

            backgroundColor: '#df0303',
            color: text,
            fontWeight: '600',
            padding: '0px 2px',
        }),
        animate(
            '10s 4s',
            keyframes([
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
            ]),
        ),
        animate(
            '2s',
            style({
                transform: 'rotate3d(0,1, 0, 360deg)',
            }),
        ),
    ]),
];

export const SCORE_ANIMATION_LONG = [
    transition(':increment', [
        style({

            backgroundColor: '#df0303',
            color: text,
            fontWeight: '600',
            padding: '0px 2px',
        }),
        animate(
            '120s 4s',
            keyframes([
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
            ]),
        ),
        animate(
            '2s',
            style({
                transform: 'rotate3d(0,1, 0, 360deg)',
            }),
        ),
    ]),
    transition(':decrement', [
        style({

            backgroundColor: '#df0303',
            color: text,
            fontWeight: '600',
            padding: '0px 2px',
        }),
        animate(
            '10s 4s',
            keyframes([
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
                style({ color: text, fontWeight: '700' }),
                style({ color: text2, fontWeight: '700' }),
            ]),
        ),
        animate(
            '2s',
            style({
                transform: 'rotate3d(0,1, 0, 360deg)',
            }),
        ),
    ]),
];
