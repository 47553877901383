/**
 *  Service for sport with non stop time after new period
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2020 livescore
 */

import { Injectable } from '@angular/core';
import SPORT_CONFIG from '@config/sport.config';
import { isArray } from 'lodash-es';

type Test = keyof typeof SPORT_CONFIG.time.topLimits;

@Injectable({
    providedIn: 'root',
})
export class RunningTime {
    private sportCodeName: Test | null = null;

    public periodLength: number;

    public handle(minute: number,
        state: string,
        addTime?: number,
        origTime?: string): [number, number | null] {
        let time: number;


        if (this.sportCodeName! in SPORT_CONFIG.time.topLimits &&
                isArray(SPORT_CONFIG.time.topLimits[this.sportCodeName!])) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [periods, ...limits] = SPORT_CONFIG.time.topLimits[this.sportCodeName!] as any[];

            if (limits.includes(origTime)) {
                time = minute;
            } else {
                time = minute + 1;
            }
        } else {
            time = minute + 1;
        }

        return [time, null];
    }

    public addSportCode(code: Test): void {
        this.sportCodeName = code;
    }
}
