import { Component, OnInit, Input, Inject } from '@angular/core';
import { trigger } from '@angular/animations';
import * as _ from 'underscore';
import { Team } from 'src/app/interfaces/match-data.interface';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SportConfigToken } from '@config/sport.config';

import { Score } from '../../../interfaces/score.interface';
import { SCORE_ANIMATION } from '../../../shared/animations/score.animation';
import { GOAL_ANIMATION } from '../../../shared/animations/goal.animation';

@Component({
    selector: 'app-match-detail-score',
    templateUrl: './match-detail-score.component.html',
    animations: [trigger('score', SCORE_ANIMATION), trigger('parentScore', GOAL_ANIMATION)],
})
export class MatchDetailScoreComponent implements OnInit {
    @Input()
    public data: Score;

    @Input('sport')
    public sportName: string | undefined;

    @Input()
    public head: boolean = false;

    @Input()
    public onlyPeriods: boolean = false;

    @Input()
    public oneline: boolean = true;

    @Input('match_state')
    public matchState: string = 'not_started';

    @Input()
    public participants: { [prop: string]: Team };

    public actualSetHome: number = 0;

    public actualSetAway: number = 0;

    public constructor(
        private trnaslate: TranslateService,
        private deviceService: DeviceDetectorService,
        @Inject(SportConfigToken) private sportConfig: Record<string, any>,
    ) {}

    public ngOnInit(): void {
        if (!this.oneline) {
            let index = 0;
            this.data.periods.forEach((val, key): void => {
                if (_.isNull(val.home_team)) {
                    return;
                }

                index = key;
            });

            this.actualSetHome = _.isUndefined(this.data.periods[index]) ? 0 : this.data.periods[index].home_team;
            this.actualSetAway = _.isUndefined(this.data.periods[index]) ? 0 : this.data.periods[index].away_team;
        }
    }

    public trackByFn(index: number, item: Record<string, any>): string {
        return item.period;
    }

    public getMainScore(type: string): number | string {
        type ExtScore = keyof Score;
        const data: ExtScore = `${type}_team` as ExtScore;

        return !this.notFormatScore ? +this.data[data]! : (this.data[data] as string);
    }

    /**
     * Check if home team is winner
     * @return {boolean}
     */
    public isWinnerHome(): boolean {
        return this.matchState === 'after' && +this.data.away_team < +this.data.home_team;
    }

    /**
     * Check if away team is winner
     * @return {boolean}
     */
    public isWinnerAway(): boolean {
        return this.matchState === 'after' && +this.data.away_team > +this.data.home_team;
    }

    public getColumName(period: Record<string, any>, currentIndex: number): string {
        const test = /^period-\d+$/.test(period.name);
        const limit = this.deviceService.isMobile() ? [5, 6] : [10, 13];
        return test ? currentIndex + 1 : this.trnaslate.instant(period.trans_name).substr(0, limit[0]);
    }

    /**
     * Detect if could make space between periods
     * @param  {number} currentIndex
     * @param  {boolean} isLast
     * @return {boolean}
     */
    public isSeparator(currentIndex: number, isLast: boolean): boolean {
        const status = true;

        if (isLast) {
            return false;
        }

        const beforeLastIndex = +this.data.periods.length - 2;

        if (!isLast && this.data.hide_last_period && currentIndex === beforeLastIndex) {
            return false;
        }
        return status;
    }

    /**
     * Normalize score
     * @param  score
     * @return {string}
     */
    public getScore(score: any, type: string, decimal: number | null = null): string {
        const data = `${type}_team`;

        if (this.isDecimalPoint && score[data] !== null && decimal !== null) {
            score[data] = `${score[data]}.${decimal}`;
        }

        return score[data] === null ? '-' : score[data];
    }

    /**
     * Normalize score tiebreak
     * @param  score
     * @return {string}
     */
    public getTieBreak(score: any, type: string): number {
        const data = `tie_break_${type}`;
        return score[data];
    }

    get isDecimalPoint(): boolean {
        return this.sportConfig.score.decimalPeriods.includes(this.sportName);
    }

    /**
     * Normalize game score
     * @param  score
     * @return {string}
     */
    public getGameScore(type: string): number | string {
        type ExtScore = keyof Score;
        const data: ExtScore = `game_score_${type}` as ExtScore;

        return +this.data[data]! === 50 ? 'A' : (this.data[data]! as number | string);
    }

    public getService(type: string): boolean {
        return !!(this.data.service && this.data.service === type);
    }

    get notFormatScore(): boolean {
        return this.sportConfig.isCricket(this.sportName);
    }
}
