import { CodeState } from '@interfaces/match-data.interface';
import { Component, OnInit, OnChanges, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import SPORT_CONFIG from '@config/sport.config';


import { LangService } from '@services/lang.service';

import $ from '../../shared/jquery';

import { Form } from '@/interfaces/participant.interface';

@Component({
    selector: 'app-last-matches',
    templateUrl: './last-matches.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastMatchesComponent implements OnInit, OnChanges {
    @Input()
    public matches: Form[];

    @Input()
    public limit: number = 100;

    @Input()
    public spread: boolean = false;

    public matchesN: Form[];

    public langLoaded = false;

    public constructor(public trans: TranslateService, protected lang: LangService, public ref: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.trans.get('web.last_matches_upcoming_short').subscribe((): void => {
            this.langLoaded = true;
            this.ref.markForCheck();
        });
    }


    public ngOnChanges(): void {
        this.matchesN = this.matches
            .map((m) => {
                const sumAll = SPORT_CONFIG.score.penalties.sumAll.includes(m.sport_name!);
                const normalTime = SPORT_CONFIG.score.penalties.normalTime.includes(m.sport_name!);
                if (m.code_state === CodeState.AP && !sumAll && !normalTime) {
                    const [home, away] = m.score.split(':', 2);
                    const [homeNormal, awayNormal] = m.score_normal_time!.split(':', 2);
                    if (+home > +away) {
                        m.score = `${+homeNormal + 1}:${awayNormal}`;
                    } else if (+home < +away) {
                        m.score = `${homeNormal}:${+awayNormal + 1}`;
                    }
                }

                return m;
            })
            .reverse();

        of(1)
            .pipe(delay(2000))
            .subscribe((): void => {
                $('[data-toggle="tooltip"]').tooltip();
            });
    }

    /**
     * Get status of match
     * @param  match
     * @return  {string}
     */
    public getStatus(match: Form): string {
        if (match.type === 'upcoming') {
            return this.trans.instant('web.last_matches_upcoming_short');
        }
        if (match.type === 'live') {
            return this.trans.instant('web.last_matches_live_short');
        }
        if (match.status === 'win') {
            return this.trans.instant('web.last_matches_win_short');
        }
        if (match.status === 'lose') {
            return this.trans.instant('web.last_matches_lose_short');
        }
        if (match.status === 'draw') {
            return this.trans.instant('web.last_matches_draw_short');
        }
        if (match.status === 'ot_win') {
            return this.trans.instant('web.last_matches_ot_win_short');
        }
        if (match.status === 'ot_lose') {
            return this.trans.instant('web.last_matches_ot_lose_short');
        }

        return '';
    }

    /**
     * Get text status of match
     * @param  match
     * @return  {string}
     */
    public getTextStatus(match: Form): string {
        const [home, away] = match.score.split(':');
        const result = Math.abs(+home - +away);
        if (match.status === 'win' || match.status === 'ot_win') {
            return `+${result}`;
        }
        if (match.status === 'lose' || match.status === 'ot_lose') {
            return `-${result}`;
        }

        return '&nbsp;';
    }

    public classOb(match: Form): string {
        let ob: string[] = [];
        if (match.type === 'upcoming') {
            ob = [...ob, 'badge-gray'];
        }
        if (match.type === 'live') {
            ob = [...ob, 'badge-primary'];
        }
        if (match.status === 'win') {
            ob = [...ob, 'badge-success'];
        }
        if (match.status === 'lose') {
            ob = [...ob, 'badge-danger'];
        }
        if (match.status === 'draw') {
            ob = [...ob, 'badge-draw'];
        }
        if (match.status === 'ot_win') {
            ob = [...ob, 'badge-success badge--split'];
        }
        if (match.status === 'ot_lose') {
            ob = [...ob, 'badge-danger badge--split'];
        }

        return ob.join(' ');
    }

    public classTextOb(match: Form): string {
        let ob: string[] = [];

        if (match.status === 'win') {
            ob = [...ob, 'badge-text-success'];
        }
        if (match.status === 'lose') {
            ob = [...ob, 'badge-text-danger'];
        }
        if (match.status === 'draw') {
            ob = [...ob, 'badge-text-draw'];
        }
        if (match.status === 'ot_win') {
            ob = [...ob, 'badge-text-success'];
        }
        if (match.status === 'ot_lose') {
            ob = [...ob, 'badge-text-danger'];
        }

        return ob.join(' ');
    }

    /**
     * Get match info base of state
     * @param  match
     * @return {string}
     */
    public getInfo(match: Form): string {
        let ret = '';

        ret += `${match.home_team} `;
        if (match.type === 'upcoming') {
            ret += '<strong>-:-</strong>';
        } else {
            ret += `<strong>${match.score}</strong>`;
        }
        ret += ` ${match.away_team}`;

        return ret;
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
