/**
 *  Animation goal
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { style, animate, transition } from '@angular/animations';

export const GOAL_ANIMATION = [
    transition(':increment', [animate(3000), style({ display: 'inline' }), animate(7000), style({ display: 'none' })]),
];

export const GOAL_ANIMATION_DEC = [
    transition(':decrement', [animate(3000), style({ display: 'inline' }), animate(7000), style({ display: 'none' })]),
];
