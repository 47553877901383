<!--Match score -->
<ng-container *ngIf="!onlyPeriods">
    <!--Oneline info -->
    <ng-container *ngIf="oneline">
        <span
            [@parentScore]="data.home_team + data.away_team"
            style="
                display: none;
                position: absolute;
                padding: 0.1rem 0rem;
                text-align: center;
                width: 100%;
                z-index: 999;
            "
            class="table-sport__cell--score__change text-uppercase small font-weight-bold"
        >
            {{ 'web.score_' + sportName | translate }}
        </span>
        <span
            [@parentScoreDown]="data.home_team + data.away_team"
            style="
                display: none;
                position: absolute;
                padding: 0.1rem 0rem;
                text-align: center;
                width: 200%;
                margin-left: -50%;
                z-index: 999;
            "
            class="table-sport__cell--score__change2 text-uppercase small font-weight-bold"
        >
            {{ 'web.score_correction' | translate }}
        </span>

        <span [@score]="data.home_team">
            <span *ngIf="data.home_extra_info.length > 0 || data.away_extra_info.length > 0; else homeScore">
                &nbsp;{{ +data.home_team }}&nbsp;
                <span class="badge badge-secondary badge-custom badge--top-left">
                    <ng-container *ngFor="let code_state of data.home_extra_info">{{
                        'status.' + sportName + '.' + code_state + '_short' | translate
                    }}</ng-container>
                </span>
            </span>
            <ng-template #homeScore>
                <span
                    *ngIf="
                        matchState != 'before' && data.home_team !== null && matchState != 'not-actual';
                        else homeNSScore
                    "
                >
                    &nbsp;{{ +data.home_team }}&nbsp;
                </span>
                <ng-template #homeNSScore>-</ng-template>
            </ng-template>
        </span>
        :
        <span [@score]="data.away_team">
            <span *ngIf="data.home_extra_info.length > 0 || data.away_extra_info.length > 0; else awayScore">
                &nbsp;{{ +data.away_team }}&nbsp;
                <span class="badge badge-secondary badge-custom badge--top-right">
                    <ng-container *ngFor="let code_state of data.away_extra_info">{{
                        'status.' + sportName + '.' + code_state + '_short' | translate
                    }}</ng-container>
                </span>
            </span>
            <ng-template #awayScore>
                <span
                    *ngIf="
                        matchState != 'before' && data.away_team !== null && matchState != 'not-actual';
                        else awayNSScore
                    "
                >
                    &nbsp;{{ +data.away_team }}&nbsp;
                </span>
                <ng-template #awayNSScore>-</ng-template>
            </ng-template>
        </span>
    </ng-container>
    <!--Sets info -->
    <ng-container *ngIf="!oneline">
        <div
            class="table-sport__cell table-sport__cell--score order-2"
            [class.app-word-nowrap]="notFormatScore"
            [class.noborder]="isMma"
            [class.app-score-color-basic]="matchState != 'inplay-live' && matchState != 'inplay-pause'"
        >
            <ng-container *ngIf="!isMma">
                <span [@score]="data.home_team">
                    <span *ngIf="data.home_extra_info.length > 0; else homeScore">
                        <span class="app-badge-setter badge-custom badge--top-set">
                            <ng-container *ngFor="let code_state of data.home_extra_info">{{
                                'status.' + sportName + '.' + code_state + '_short' | translate
                            }}</ng-container> </span
                        >{{ isWalkOverNullScore ? '-' : !notFormatScore ? +data.home_team : data.home_team }}
                    </span>
                    <ng-template #homeScore>
                        <ng-container
                            *ngIf="
                                matchState != 'before' && data.home_team !== null && matchState != 'not-actual';
                                else homeNSScore
                            "
                        >
                            {{ isWalkOverNullScore ? '-' : !notFormatScore ? +data.home_team : data.home_team }}
                        </ng-container>
                        <ng-template #homeNSScore>-</ng-template>
                    </ng-template>
                </span>
                <span [@score]="data.away_team">
                    <span *ngIf="data.away_extra_info.length > 0; else awayScore">
                        <span class="app-badge-setter badge-custom badge--bottom-set">
                            <ng-container *ngFor="let code_state of data.away_extra_info">{{
                                'status.' + sportName + '.' + code_state + '_short' | translate
                            }}</ng-container> </span
                        >{{ isWalkOverNullScore ? '-' : !notFormatScore ? +data.away_team : data.away_team }}
                    </span>
                    <ng-template #awayScore>
                        <ng-container
                            *ngIf="
                                matchState != 'before' && data.away_team !== null && matchState != 'not-actual';
                                else awayNSScore
                            "
                        >
                            {{ isWalkOverNullScore ? '-' : !notFormatScore ? +data.away_team : data.away_team }}
                        </ng-container>
                        <ng-template #awayNSScore>-</ng-template>
                    </ng-template>
                </span>
            </ng-container>
            <ng-container *ngIf="isMma">
                <span>
                    <ng-container *ngIf="data.home_team === 1; else nowinner">
                        {{ 'web.winner' | translate }}
                    </ng-container>
                    <ng-template #nowinner>&nbsp;</ng-template>
                </span>
                <span>
                    <ng-container *ngIf="data.away_team === 1; else nowinner">
                        {{ 'web.winner' | translate }}
                    </ng-container>
                    <ng-template #nowinner>&nbsp;</ng-template>
                </span>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
<!--End Match score -->

<!--Periods -->
<ng-container *ngIf="!isPeriodsHidden && onlyPeriods && oneline && !head">
    <div class="d-flex justify-content-end" style="width: 100%">
        <ng-container
            *ngFor="
                let period of data.periods;
                trackBy: trackByFn;
                last as lastPeriod;
                first as firstPeriod;
                index as currentIndex
            "
        >
            <div class="table-sport__cell table-sport__cell--period flex-row">
                <ng-container *ngIf="!lastPeriod || (lastPeriod && !data.hide_last_period); else nothing">
                    <span [@score]="period.home_team"
                        >{{ getScore(period.home_team, period.home_team_points)
                        }}<sup *ngIf="period.tie_break_home != null"> {{ period.tie_break_home }}</sup></span
                    >:<span [@score]="period.away_team"
                        >{{ getScore(period.away_team, period.away_team_points)
                        }}<sup *ngIf="period.tie_break_away != null"> {{ period.tie_break_away }}</sup></span
                    >
                    <span *ngIf="isSeparator(currentIndex, lastPeriod)">&nbsp;</span>
                </ng-container>
                <ng-template #nothing> &nbsp; </ng-template>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="onlyPeriods && (!oneline || head)">
    <div class="match-info__table small py-2 text-center">
        <ng-container *ngIf="head">
            <div class="match-info__table__row">
                <div
                    class="match-info__table__cell text-danger"
                    *ngFor="let period of data.periods; trackBy: trackByFn"
                >
                    {{ period.trans_name | translate }}
                </div>
            </div>
        </ng-container>
        <div class="match-info__table__row">
            <div class="match-info__table__cell" *ngIf="data.periods.length == 0">&nbsp;</div>
            <div
                class="match-info__table__cell table-sport__cell--period"
                *ngFor="
                    let period of data.periods;
                    trackBy: trackByFn;
                    last as lastPeriod;
                    first as firstPeriod;
                    index as currentIndex
                "
            >
                <ng-container *ngIf="!lastPeriod || (lastPeriod && !data.hide_last_period); else nothing">
                    <span [@score]="period.home_team"
                        >{{ getScore(period.home_team, period.home_team_points)
                        }}<sup *ngIf="period.tie_break_home != null"> {{ period.tie_break_home }}</sup></span
                    >:<span [@score]="period.away_team"
                        >{{ getScore(period.away_team, period.away_team_points)
                        }}<sup *ngIf="period.tie_break_away != null"> {{ period.tie_break_away }}</sup></span
                    >
                    <span *ngIf="isSeparator(currentIndex, lastPeriod)">&nbsp;</span>
                </ng-container>
                <ng-template #nothing> &nbsp; </ng-template>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!oneline">
    <span *ngIf="oneline && data.periods.length > 0">(</span>
    <ng-container
        *ngIf="
            data.periods.length > 0 && data.periods.length < sportConfig.displayType.maxDisplayPeriods;
            else noperiods
        "
    >
        <div
            class="table-sport__cell table-sport__cell--periods table-sport__cell--periods--xl order-1"
            [class.d-none]="data.periods.length > 5"
            [class.d-sm-flex]="data.periods.length > 5"
        >
            <ng-container
                *ngFor="
                    let period of data.periods;
                    trackBy: trackByFn;
                    last as lastPeriod;
                    first as firstPeriod;
                    index as currentIndex
                "
            >
                <ng-container *ngIf="!lastPeriod || (lastPeriod && !data.hide_last_period)">
                    <div class="" *ngIf="head">
                        <strong>
                            {{ period.trans_name | translate }}
                        </strong>
                    </div>
                    <!--Sets info -->
                    <ng-container *ngIf="!oneline">
                        <div
                            class="table-sport__cell table-sport__cell--period"
                            [class.table-sport__cell--narrow]="isLong"
                        >
                            <span [@score]="period.home_team"
                                >{{ getScore(period.home_team)
                                }}<sup *ngIf="period.tie_break_home != null"> {{ period.tie_break_home }}</sup></span
                            >
                            <span [@score]="period.away_team"
                                >{{ getScore(period.away_team)
                                }}<sup *ngIf="period.tie_break_away != null"> {{ period.tie_break_away }}</sup></span
                            >
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <span *ngIf="oneline && data.periods.length > 0">)</span>
            <!-- <div *ngIf="!oneline && matchState == 'inplay-live'" class="table-sport__cell table-sport__cell--period">
                    <span><strong>   {{ actualSetHome }} </strong></span>
                    <span><strong>{{ actualSetAway }}</strong></span>
                </div> -->

            <!--Fifteens ect. -->
            <ng-container
                *ngIf="
                    !onlyPeriods &&
                    data.game_score_home &&
                    data.game_score_home !== null &&
                    data.game_score_away !== null
                "
            >
                <div *ngIf="head">
                    <strong>
                        {{ 'web.game_score_head' | translate }}
                    </strong>
                </div>

                <div
                    class="table-sport__cell table-sport__cell--period table-sport__cell table-sport__cell--fifteens ml-auto"
                >
                    <span class="serve" *ngIf="data.service && data.service == 'home'; else homeService">{{
                        getGameScore(data.game_score_home)
                    }}</span>
                    <ng-template #homeService
                        ><span>{{ getGameScore(data.game_score_home) }}</span></ng-template
                    >
                    <span class="serve" *ngIf="data.service && data.service == 'away'; else awayService">{{
                        getGameScore(data.game_score_away)
                    }}</span>
                    <ng-template #awayService
                        ><span>{{ getGameScore(data.game_score_away) }}</span></ng-template
                    >
                </div>
            </ng-container>
            <!--End Fifteens -->
        </div>
    </ng-container>
    <ng-template #noperiods>
        <div
            class="table-sport__cell table-sport__cell--periods table-sport__cell--periods--xl order-1"
            [class.d-none]="data.periods.length > 5"
            [class.d-sm-flex]="data.periods.length > 5"
        >
            <div class="table-sport__cell table-sport__cell--period">
                <span class="invisible">0</span><span class="invisible">0</span>
            </div>
        </div>
    </ng-template>
</ng-container>
<!--End Periods -->

<!--Fifteens ect. -->
<ng-container
    *ngIf="onlyPeriods && data.game_score_home && data.game_score_home !== null && data.game_score_away !== null"
>
    <div class="match-info__table small py-2 text-center">
        <ng-container *ngIf="head">
            <div class="match-info__table__row">
                <div class="match-info__table__cell text-danger">
                    {{ 'web.game_score_head' | translate }}
                </div>
            </div>
        </ng-container>
        <div class="match-info__table__row">
            <div class="match-info__table__cell">
                <span class="serve-before" *ngIf="data.service && data.service == 'home'; else homeService">{{
                    getGameScore(data.game_score_home)
                }}</span>
                <ng-template #homeService>{{ getGameScore(data.game_score_home) }}</ng-template
                >:<span class="serve-after" *ngIf="data.service && data.service == 'away'; else awayService">{{
                    getGameScore(data.game_score_away)
                }}</span>
                <ng-template #awayService>{{ getGameScore(data.game_score_away) }}</ng-template>
            </div>
        </div>
    </div>
</ng-container>
<!--End Fifteens -->
