/* eslint-disable camelcase */
/**
 *  Participant detail [GET /api/participant/{id}]
 *  @author Livescore <@score-stats.com>
 *  @copyright 2019 livescore
 */

import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { CodeState, MatchData, News } from '@interfaces/match-data.interface';

export enum Status {
    DRAW = 'draw',
    WIN = 'win',
    OTWIN = 'ot_win',
    LOSE = 'lose',
    OTLOSE = 'ot_lose',
}


export type Stats = InstanceType<typeof ParticipantStats>;


interface Tournament {
    category_name: string;
    sub_tournament_name: string;
    tournament_id: string;
}

export interface Form {
    type: string;
    status: Status | null;
    start_date: string;
    score: string;
    home_team: string;
    away_team: string;
    score_period_1?: string;
    code_state?: CodeState;
    score_normal_time?: string;
    corners_home?: number | null;
    corners_away?: number | null;
    sport_name?: string;
}
interface Player {
    id: number;
    player_id: number;
    position: string;
    number: number;
    flag: string;
    name: string;
    age?: number;
    injury?: boolean;
    goals?: number;
    red_card?: number;
    yellow_card?: number;
    role?: string;
    player_name?: string;

}

interface MetaItem {
    type: string;
    name: string;
    translation: string;
    value: string;
}


export interface TennisBilanceStats {
    [prop: string]: any;
    id: number;
    year: number;
    carpet_indoor_competitions_played: number | null;
    carpet_indoor_competitions_won: number | null;
    carpet_indoor_competitions_lost: number | null;
    carpet_indoor_matches_played: number | null;
    carpet_indoor_matches_won: number | null;
    carpet_indoor_matches_lost: number | null;
    competitions_played: number | null;
    competitions_won: number | null;
    grass_competitions_played: number | null;
    grass_competitions_won: number | null;
    grass_competitions_lost: number | null;
    grass_matches_played: number | null;
    grass_matches_won: number | null;
    grass_matches_lost: number | null;
    hardcourt_indoor_competitions_played: number | null;
    hardcourt_indoor_competitions_won: number | null;
    hardcourt_indoor_competitions_lost: number | null;
    hardcourt_indoor_matches_played: number | null;
    hardcourt_indoor_matches_won: number | null;
    hardcourt_indoor_matches_lost: number | null;
    hardcourt_matches_played: number | null;
    hardcourt_matches_won: number | null;
    hardcourt_matches_lost: number | null;
    hardcourt_competitions_played: number | null;
    hardcourt_competitions_won: number | null;
    hardcourt_competitions_lost: number | null;
    hardcourt_outdoor_competitions_played: number | null;
    hardcourt_outdoor_competitions_won: number | null;
    hardcourt_outdoor_competitions_lost: number | null;
    hardcourt_outdoor_matches_played: number | null;
    hardcourt_outdoor_matches_won: number | null;
    hardcourt_outdoor_matches_lost: number | null;
    matches_played: number | null;
    matches_won: number | null;
    participant_id: number | null;
    red_clay_competitions_played: number | null;
    red_clay_competitions_won: number | null;
    red_clay_competitions_lost: number | null;
    red_clay_matches_played: number | null;
    red_clay_matches_won: number | null;
    red_clay_matches_lost: number | null;
    synthetic_indoor_competitions_played: number | null;
    synthetic_indoor_competitions_won: number | null;
    synthetic_indoor_competitions_lost: number | null;
    synthetic_indoor_matches_played: number | null;
    synthetic_indoor_matches_won: number | null;
    synthetic_indoor_matches_lost: number | null;
    unknown_competitions_played: number | null;
    unknown_competitions_won: number | null;
    unknown_competitions_lost: number | null;
    unknown_matches_played: number | null;
    unknown_matches_won: number | null;
    unknown_matches_lost: number | null;
}

export interface SeasonStats {
    [prop:string]:any;
    average_ball_possession: string;
    cards_given: string;
    corner_kicks: string;
    free_kicks: string;
    goals_by_foot: string;
    goals_by_head: string;
    goals_conceded: string;
    goals_conceded_first_half: string;
    goals_conceded_second_half: string;
    goals_scored: string;
    goals_scored_first_half: string;
    goals_scored_second_half: string;
    matches_played: string;
    offsides: string;
    penalties_missed: string;
    red_cards: string;
    shots_blocked: string;
    shots_off_target: string;
    shots_on_bar: string;
    shots_on_post: string;
    shots_on_target: string;
    shots_total: string;
    tournamentName: string;
    tournamentYear: string;
    yellow_cards: string;
    yellow_red_cards: string;
    goals: string;
    matches_lost: string;
    matches_won: string;
    number_of_penalties: string;
    overtime_losses: string;
    penalty_minutes: string;
    save_percentage: string;
    saves: string;
    shooting_percentage: string;
    shots: string;
    shutouts: string;
    opta_competition: string;
    opta_season: string;
    opta_team:string;


}
interface ParticipantBase {
    readonly id: number;
    readonly name: string;
    readonly image: string;
    readonly date_of_birth?: string;
    readonly height?: string;
    readonly weight?: string;
    readonly place_of_birth?: string;
    readonly home_stadium?: string;
    readonly country: {name: string; isoCode: string} | null;
    tennis_points_single: number;
    tennis_rankings_single?: number;
    tennis_points_double: number;
    tennis_rankings_double?: number;
    season_stats?: {[prop:number]: Partial<SeasonStats>} | null;
    form: Form[];
    meta?: { main?: MetaItem[], basic?: MetaItem[], statistics?: MetaItem[] };
    readonly seasonStats?: {
        seasonId: number;
        name: string;
        data: {
            ballPossession: number, freeKicks: number, throwIns: number, cornerKicks: number,
            fouls: number, shotsBlocked: number,
            shotsTotal: number, shotsOnTarget: number, offsides: number, injuries: number,
            goalKicks: number, shotsOffTarget: number, yellowCards: number, cardsGiven: number,
            goalsConceded: number, goalsInPowerPlay: number, goalsWhileShortHanded: number,
            penalties: number, penaltyMinutes: number, powerPlays: number, puckPossession: number,
            saves: number, shotsOnGoal: number, shutouts: number,
            offensiveRebounds: number, defensiveRebounds: number, teamRebounds: number, teamTurnovers: number,
            leaderPoints: number, leaderAssists: number,
            leaderRebounds: number, rebounds: number,
            freeThrowAttemptsTotal: number,
            freeThrowAttemptsSuccessful: number,
            twoPointAttemptsTotal: number,
            twoPointAttemptsSuccessful: number,
            threePointAttemptsTotal: number, threePointAttemptsSuccessful: number,
            timeouts: number, biggestLead: number,
            timeSpentInLead: number, teamLeads: number,
            sevenMGoals: number, goalsScored: number, blocks: number, suspensions: number,
            redCards: number, steals: number,
            technical_fouls: number,
            assists: number, fieldGoals: number,
        },
    }
}
export interface ParticipantCompareInterface {
    readonly first: {base: ParticipantBase},
    readonly second: {base: ParticipantBase},
    data: {
        finished?: MatchData[],
        live?: MatchData[],
        not_started?: MatchData[],
    }
}

export interface ParticipantInterface extends ParticipantBase {
    players?: Player[];
    balance?: TennisBilanceStats[];
    tournament?: Tournament[];
    data: { all: MatchData[]; finished: MatchData[]; not_started: MatchData[] };
    goals_scored_conceded?: [];
    news?: News[];
    venue?: Record<'place' | 'stadium' | 'stadium_capacity' | 'stadium_coords', string | number>;
    international_players?: number;
    national_team_players?: number;
    isNationalTeam: boolean;
    transfers_from:Player[];
    transfers_to:Player[];
    birthplace?:Record<'city' | 'country', string>;

}
