import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, Inject } from '@angular/core';
import { trigger } from '@angular/animations';
import * as _ from 'underscore';
import { CodeState } from '@interfaces/match-data.interface';

import { SportConfigToken } from '@config/sport.config';

import { Score } from '../../../interfaces/score.interface';
import { SCORE_ANIMATION } from '../../../shared/animations/score.animation';
import { GOAL_ANIMATION, GOAL_ANIMATION_DEC } from '../../../shared/animations/goal.animation';

@Component({
    selector: 'app-default-score',
    templateUrl: './default-score.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('score', SCORE_ANIMATION),
        trigger('parentScore', GOAL_ANIMATION),
        trigger('parentScoreDown', GOAL_ANIMATION_DEC),
    ],
})
export class DefaultScoreComponent implements OnInit, OnChanges {
    @Input()
    public data: Score;

    @Input('sport')
    public sportName: string;

    @Input()
    public head: boolean = false;

    @Input()
    public onlyPeriods: boolean = false;

    @Input()
    public oneline: boolean = true;

    @Input('match_state')
    public matchState: string = 'not_started';

    @Input()
    public codeState: CodeState | null = null;

    public actualSetHome: number = 0;

    public actualSetAway: number = 0;

    public isLong: boolean = false;

    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {}

    public ngOnInit(): void {
        if (!this.oneline) {
            let index = 0;
            this.data.periods.forEach((val, key): void => {
                if (_.isNull(val.home_team)) {
                    return;
                }

                index = key;
            });

            this.actualSetHome = _.isUndefined(this.data.periods[index]) ? 0 : this.data.periods[index].home_team;
            this.actualSetAway = _.isUndefined(this.data.periods[index]) ? 0 : this.data.periods[index].away_team;
        }
    }

    public ngOnChanges(): void {
        this.isLong = this.sportConfig.displayType.lotOfPeriods.includes(this.sportName);
    }

    public trackByFn(index: number, item: Record<string, any>): string {
        return item.period;
    }

    /**
     * Hidden periods for sport
     */
    get isPeriodsHidden(): boolean {
        return this.sportConfig.score.hidePeriodsMatchList.includes(this.sportName);
    }

    get isWalkOverNullScore(): boolean {
        return this.codeState === CodeState.WALKOVER && +this.data.home_team === 0 && +this.data.away_team === 0;
    }

    /**
     * Detect if could make space between periods
     * @param  {number} currentIndex
     * @param  {boolean} isLast
     * @return {boolean}
     */
    public isSeparator(currentIndex: number, isLast: boolean): boolean {
        const status = true;

        if (isLast) {
            return false;
        }

        const beforeLastIndex = +this.data.periods.length - 2;

        if (!isLast && this.data.hide_last_period && currentIndex === beforeLastIndex) {
            return false;
        }
        return status;
    }

    get notFormatScore(): boolean {
        return this.sportConfig.isCricket(this.sportName);
    }

    get isMma(): boolean {
        return this.sportConfig.isMma(this.sportName) || this.sportConfig.isBox(this.sportName);
    }

    get isDecimalPoint(): boolean {
        return this.sportConfig.score.decimalPeriods.includes(this.sportName);
    }

    /**
     * Normalize gamescore
     * @param  score
     * @return {string}
     */
    public getGameScore(val: number): number | string {
        return +val === 50 ? 'A' : val;
    }

    /**
     * Normalize score
     * @param  score
     * @return {string}
     */
    public getScore(score: any, decimal: number | null = null): string {
        if (this.isDecimalPoint && score !== null && decimal !== null) {
            score = `${score}.${decimal}`;
        }
        return score === null ? '-' : score;
    }
}
