<ng-container *ngIf="!oneline && data.periods.length < 15">
    <div class="table-responsive mt-3">
        <table class="table table--parts">
            <thead>
                <tr class="score-label">
                    <th></th>
                    <th *ngIf="matchState != 'after' && matchState != 'before'"></th>
                    <th></th>
                    <th>&nbsp;</th>
                    <th
                        *ngFor="
                            let period of data.periods;
                            trackBy: trackByFn;
                            last as lastPeriod;
                            first as firstPeriod;
                            index as currentIndex
                        "
                    >
                        {{ getColumName(period, currentIndex) }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table--parts__name" [class.font-weight-bold]="isWinnerHome()">
                        {{ participants.home_team.name }}
                    </td>
                    <td class="border-left-0 pr-0 text-right">
                        <span class="text-danger" [class.serve]="getService('home')">&nbsp;</span>
                    </td>
                    <td *ngIf="matchState != 'after' && matchState != 'before'">{{ getGameScore('home') }}</td>
                    <td class="table--parts__score">
                        <ng-container
                            *ngIf="
                                matchState != 'before' && matchState != 'cancelled' && matchState != 'not-actual';
                                else Score
                            "
                        >
                            <span
                                class="text-bold"
                                [class.text-danger2]="matchState == 'inplay-live' || matchState == 'inplay-pause'"
                                >{{ getMainScore('home') }}</span
                            >
                        </ng-container>
                        <ng-template #Score>-</ng-template>
                    </td>
                    <td
                        *ngFor="
                            let period of data.periods;
                            trackBy: trackByFn;
                            last as lastPeriod;
                            first as firstPeriod;
                            index as currentIndex
                        "
                    >
                        <span [@score]="period.home_team"
                            >{{ getScore(period, 'home', period.home_team_points) }}
                            <sup class="app-mobile-periods-detail__sup" *ngIf="getTieBreak(period, 'home') != null">
                                {{ getTieBreak(period, 'home') }}
                            </sup>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="table--parts__name" [class.font-weight-bold]="isWinnerAway()">
                        {{ participants.away_team.name }}
                    </td>
                    <td class="border-left-0 pr-0 text-right">
                        <span class="text-danger" [class.serve]="getService('away')">&nbsp;</span>
                    </td>
                    <td *ngIf="matchState != 'after' && matchState != 'before'">{{ getGameScore('away') }}</td>
                    <td class="table--parts__score">
                        <ng-container
                            *ngIf="
                                matchState != 'before' && matchState != 'cancelled' && matchState != 'not-actual';
                                else Score
                            "
                        >
                            <span
                                class="text-bold"
                                [class.text-danger2]="matchState == 'inplay-live' || matchState == 'inplay-pause'"
                                >{{ getMainScore('away') }}</span
                            >
                        </ng-container>
                        <ng-template #Score>-</ng-template>
                    </td>
                    <td
                        *ngFor="
                            let period of data.periods;
                            trackBy: trackByFn;
                            last as lastPeriod;
                            first as firstPeriod;
                            index as currentIndex
                        "
                    >
                        <span [@score]="period.away_team"
                            >{{ getScore(period, 'away', period.away_team_points) }}
                            <sup *ngIf="getTieBreak(period, 'away') != null">
                                {{ getTieBreak(period, 'away') }}
                            </sup>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>
